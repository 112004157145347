
import { Component, Watch } from 'vue-property-decorator';

import { appName } from '@/env';
import {
  readDashboardMiniDrawer, readDashboardShowDrawer, readHasAdminAccess, readSites, readSiteCode, readPrefixSiteUrl,
} from '@/store/main/getters';
import { commitSetDashboardShowDrawer, commitSetDashboardMiniDrawer, commitSetSiteCode } from '@/store/main/mutations';
import { dispatchUserLogOut, dispatchGetSites } from '@/store/main/actions';
import { store } from '@/store';
import VueBase from '@/components/VueBase.vue';
import { Route } from 'vue-router';

const routeGuardMain = async (to, from, next) => {
  if (to.params.site) {
    commitSetSiteCode(store, to.params.site);
  } else {
    commitSetSiteCode(store, '');
  }

  if (to.path === '/main') {
    next(`${readPrefixSiteUrl(store)}/main/dashboard`);
  } else if (to.path === `/${to.params.site}/main`) {
    next(`/${to.params.site}/main/dashboard`);
  } else {
    next();
  }
};

@Component
export default class Main extends VueBase {
  public appName = appName;
  public siteCode: string = readSiteCode(store);

  public beforeRouteEnter(to, from, next) {
    routeGuardMain(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    routeGuardMain(to, from, next);
  }

  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store);
  }

  get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }

  set showDrawer(value) {
    commitSetDashboardShowDrawer(this.$store, value);
  }

  public switchShowDrawer() {
    commitSetDashboardShowDrawer(
      this.$store,
      !readDashboardShowDrawer(this.$store),
    );
  }

  public switchMiniDrawer() {
    commitSetDashboardMiniDrawer(
      this.$store,
      !readDashboardMiniDrawer(this.$store),
    );
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  get sites() {
    return readSites(this.$store);
  }

  public async logout() {
    await dispatchUserLogOut(this.$store);
  }

  public async mounted() {
    await dispatchGetSites(this.$store);
  }

  public changeSite() {
    commitSetSiteCode(this.$store, this.siteCode);
    if (this.$router.currentRoute.params.site !== this.siteCode) {
      if (this.$router.currentRoute.params.site) {
        this.$router.push(`/${this.siteCode}/${this.$router.currentRoute.path.split('/').slice(2).join('/')}`);
      } else {
        this.$router.push(`/${this.siteCode}${this.$router.currentRoute.path}`);
      }
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(to: Route) {
    if (to.params.site) {
      this.siteCode = to.params.site;
    } else {
      this.siteCode = '';
    }
  }
}
